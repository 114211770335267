import { Link, Outlet } from 'react-router-dom';
import './nav.scss';
import { AppBar, Toolbar, Box, Button } from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import { FormattedMessage } from 'react-intl';
import { useMsal } from '@azure/msal-react';
import axios from 'axios';
import { useMemo } from 'react';
import { CLAIMS } from 'models/claims';

export const Nav: React.FC = () => {
  const { instance } = useMsal();
  const user = instance.getActiveAccount();

  const canExportParticipants = useMemo(() => {
    const idTokenClaims = user?.idTokenClaims;
    if (idTokenClaims && idTokenClaims.extension_UserPermissions) {
      const userPermissionsStr = idTokenClaims.extension_UserPermissions as string;
      const userPermissions = userPermissionsStr.split(',');
      return userPermissions.includes(CLAIMS.PARTICIPANTS_READ_ALL);
    } else {
      return false;
    }
  }, [user]);

  const handleLogout = () => {
    instance.logoutRedirect({ account: instance.getActiveAccount() });
  };
  const handleParticipantExport = async () => {
    const path = '/participants/export';
    try {
      const response = await axios.post(path, {
        responseType: 'blob',
      });

      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = 'participant_data.csv';
      document.body.appendChild(link);
      // Trigger a click on the link to start the download
      link.click();
      document.body.removeChild(link);
    } catch (error: any) {
      console.error('Error downloading file:', error.message);
    }
  };

  return (
    <div>
      <AppBar position="static" color="primary" elevation={0}>
        <Toolbar>
          <Box display="flex" justifyContent="space-between" width="100%">
            <Box display="flex" gap={2}>
              <Button color="inherit" component={Link} to="/">
                Patient Registration
              </Button>
              <Button color="inherit" component={Link} to="/dashboard">
                Study Dashboard
              </Button>
              {canExportParticipants && (
                <Button color="inherit" onClick={handleParticipantExport}>
                  Patient Export
                </Button>
              )}
            </Box>
            <Button color="inherit" onClick={handleLogout}>
              <LogoutIcon sx={{ marginTop: '4px' }} />
              <FormattedMessage id="app.logoutButton.text"></FormattedMessage>
            </Button>
          </Box>
        </Toolbar>
      </AppBar>
      <Outlet />
    </div>
  );
};
