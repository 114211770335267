import { Configuration, LogLevel, RedirectRequest } from '@azure/msal-browser';

const DEV_DOMAIN = 'dev.speechdx.org';
const PROD_DOMAIN = 'speechdx.org';

const localConfig: EnvironmentConfiguration = {
  signInFlowName: 'B2C_1_speechdx-registration-sign-in',
  authority: 'https://speechdxeudev.b2clogin.com/speechdxeudev.onmicrosoft.com/B2C_1_speechdx-registration-sign-in',
  authorityDomain: 'speechdxeudev.b2clogin.com',
  domain: 'localhost:3000',
  appClientId: 'ca7572ef-47cf-431a-a55c-78f716567c0c',
  apiBasePath: 'https://eu.dev.api.speechdx.org/api/',
};

const devConfig: EnvironmentConfiguration = {
  signInFlowName: 'B2C_1_speechdx-registration-sign-in',
  authority: 'https://speechdxeudev.b2clogin.com/speechdxeudev.onmicrosoft.com/B2C_1_speechdx-registration-sign-in',
  authorityDomain: 'speechdxeudev.b2clogin.com',
  domain: DEV_DOMAIN,
  appClientId: 'ca7572ef-47cf-431a-a55c-78f716567c0c',
  apiBasePath: 'https://eu.dev.api.speechdx.org/api/',
};

const prodConfig: EnvironmentConfiguration = {
  signInFlowName: 'B2C_1_speechdx-registration-sign-in',
  authority: 'https://speechdxeuprod.b2clogin.com/speechdxeuprod.onmicrosoft.com/B2C_1_speechdx-registration-sign-in',
  authorityDomain: 'speechdxeuprod.b2clogin.com',
  domain: PROD_DOMAIN,
  appClientId: '4de74215-a9a5-488a-80b2-875b50dc8962',
  apiBasePath: 'https://eu.prod.api.speechdx.org/api/',
};

const getEnvironmentConfig = () => {
  if (window.location.hostname === PROD_DOMAIN) {
    return prodConfig;
  } else if (window.location.hostname === DEV_DOMAIN) {
    return devConfig;
  } else if (window.location.hostname.includes('localhost')) {
    return localConfig;
  } else {
    return {} as EnvironmentConfiguration;
  }
};

export const getConfig = () => {
  const environmentConfig = getEnvironmentConfig();
  const msalConfig: Configuration = {
    auth: {
      clientId: environmentConfig.appClientId,
      authority: environmentConfig.authority,
      knownAuthorities: [environmentConfig.authorityDomain],
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    },
    cache: {
      cacheLocation: 'sessionStorage',
      storeAuthStateInCookie: false,
    },
    system: {
      loggerOptions: {
        loggerCallback: (level, message, containsPii) => {
          if (containsPii) {
            return;
          }
          switch (level) {
            case LogLevel.Error:
              console.error(message);
              return;
            case LogLevel.Info:
              console.info(message);
              return;
            case LogLevel.Verbose:
              console.debug(message);
              return;
            case LogLevel.Warning:
              console.warn(message);
              return;
            default:
              return;
          }
        },
      },
    },
  };

  return {
    apiEndpoint: environmentConfig.apiBasePath,
    msalConfig: msalConfig,
  };
};

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest: RedirectRequest = {
  scopes: ['openid', 'profile', 'offline_access', getEnvironmentConfig().appClientId],
  prompt: 'login',
  // extraQueryParameters: new StringDict(),
};

interface EnvironmentConfiguration {
  signInFlowName: string;
  authority: string;
  authorityDomain: string;
  domain: string;
  appClientId: string;
  apiBasePath: string;
}
