import { CircularProgress } from '@mui/material';
import styles from './LoadingScreen.module.scss';
import { FormattedMessage } from 'react-intl';

interface Props {
  messageId: string;
}

const LoadingScreen: React.FC<Props> = ({ messageId }) => {
  return (
    <div className={styles.loadingContainer}>
      <CircularProgress size={200} color={'primary'} />
      <div>
        <FormattedMessage id={messageId}></FormattedMessage>
      </div>
    </div>
  );
};

export default LoadingScreen;
