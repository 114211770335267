import React from 'react';
import styles from './Layout.module.scss';

interface Props {
  children?: React.ReactNode;
  loading: boolean;
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <div className={styles.container}>
      <div className={styles.leftColumn} />
      <div className={styles.rightColumn}>{children}</div>
    </div>
  );
};

export default Layout;
