import { useEffect, useState } from 'react';
import styles from './Form.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  TextField,
  Radio,
  FormControl,
  FormControlLabel,
  RadioGroup,
  Button,
  Select,
  SelectChangeEvent,
  Grid,
  Typography,
} from '@mui/material';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import { languagePacks } from '../../supportedLanguagePacks';
import { FormData } from '../../models/formData';
import { AmyloidStatus, ApoEStatus, Diagnosis, Gender } from '../../models/participantRegistration';
import { genderOptions } from '../../models/genderOptions';
import { diagnosisOptions } from '../../models/diagnosisOptions';
import { apoEStatusOptions } from '../../models/apoEStatusOptions';
import { amyloidStatusOptions } from '../../models/amyloidStatusOptions';

interface Props {
  contentPackIds: string[];
  error: boolean;
  onSubmit: (formData: FormData) => void;
  siteId: string | undefined;
}

const Form: React.FC<Props> = ({ contentPackIds, error, onSubmit, siteId }) => {
  const [genderOptionIndex, setGenderOptionIndex] = useState(0);
  const [diagnosisOptionIndex, setDiagnosisOptionIndex] = useState(0);
  const [apoEStatusOptionIndex, setApoEStatusOptionIndex] = useState(0);
  const [amyloidStatusOptionIndex, setAmyloidStatusOptionIndex] = useState(0);

  const [ageError, setAgeError] = useState(false);
  const [educationYearsError, setEducationYearsError] = useState(false);

  const [registrationForm, setRegistrationForm] = useState<FormData>({
    participantID: '',
    contentPackID: '',
    confirmParticipantID: '',
    age: '',
    gender: Gender.U,
    diagnosis: Diagnosis.U,
    apoEStatus: ApoEStatus.U,
    amyloidStatus: AmyloidStatus.U,
    educationYears: '',
  });

  const [err, setErr] = useState(error);
  const intl = useIntl();

  useEffect(() => {
    if (contentPackIds && contentPackIds.length === 1) {
      setRegistrationForm({
        ...registrationForm,
        contentPackID: languagePacks[contentPackIds[0]].value,
      });
    }
  }, [contentPackIds]);

  const handleAgeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const age = parseInt(value);
    if (age < 18 || age > 99) {
      setAgeError(true);
    } else {
      setAgeError(false);
    }
  };

  const handleEducationYearsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    const educationYears = parseInt(value);
    if (educationYears < 0 || educationYears > 25) {
      setEducationYearsError(true);
    } else {
      setEducationYearsError(false);
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setRegistrationForm({
      ...registrationForm,
      [name]: value,
    });
    if (name === 'age') {
      handleAgeChange(e);
    } else if (name === 'educationYears') {
      handleEducationYearsChange(e);
    }
  };

  const handleRadioChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRegistrationForm({
      ...registrationForm,
      contentPackID: e.target.value,
    });
  };

  const handleClearInput = (name: string) => {
    setRegistrationForm({
      ...registrationForm,
      [name]: '',
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    // TODO: do we need to prevent default?
    event.preventDefault();
    // Do not submit if participant ID and confirm ID do not match
    if (registrationForm.participantID !== registrationForm.confirmParticipantID) {
      setErr(true);
      return;
    }
    onSubmit({
      participantID: registrationForm.participantID,
      contentPackID: registrationForm.contentPackID,
      confirmParticipantID: registrationForm.confirmParticipantID,
      age: registrationForm.age,
      gender: registrationForm.gender,
      diagnosis: registrationForm.diagnosis,
      apoEStatus: registrationForm.apoEStatus,
      amyloidStatus: registrationForm.amyloidStatus,
      educationYears: registrationForm.educationYears,
    });
  };

  const handleInputClick = () => {
    setErr(false);
  };

  const handleDropDownChange = (event: SelectChangeEvent) => {
    let selectedGender: Gender;
    switch (event.target.value) {
      case '0':
        selectedGender = Gender.U;
        break;
      case '1':
        selectedGender = Gender.F;
        break;
      case '2':
        selectedGender = Gender.M;
        break;
      case '3':
        selectedGender = Gender.O;
        break;
      default:
        selectedGender = Gender.U;
        break;
    }
    setRegistrationForm({
      ...registrationForm,
      gender: selectedGender,
    });
    setGenderOptionIndex(+event.target.value);
  };

  const handleDiagnosisDropDownChange = (event: SelectChangeEvent) => {
    let selection: Diagnosis;
    switch (event.target.value) {
      case '0':
        selection = Diagnosis.U;
        break;
      case '1':
        selection = Diagnosis.CN;
        break;
      case '2':
        selection = Diagnosis.SCD;
        break;
      case '3':
        selection = Diagnosis.MCI;
        break;
      case '4':
        selection = Diagnosis.MAD;
        break;
      case '5':
        selection = Diagnosis.FD;
        break;
      case '6':
        selection = Diagnosis.LBD;
        break;
      case '7':
        selection = Diagnosis.VD;
        break;
      case '8':
        selection = Diagnosis.PD;
        break;
      case '9':
        selection = Diagnosis.O;
        break;

      default:
        selection = Diagnosis.U;
        break;
    }
    setRegistrationForm({
      ...registrationForm,
      diagnosis: selection,
    });
    setDiagnosisOptionIndex(+event.target.value);
  };

  const handleApoEStatusDropDownChange = (event: SelectChangeEvent) => {
    let selection: ApoEStatus;
    switch (event.target.value) {
      case '0':
        selection = ApoEStatus.U;
        break;
      case '1':
        selection = ApoEStatus.A4HO;
        break;
      case '2':
        selection = ApoEStatus.A4HE;
        break;
      case '3':
        selection = ApoEStatus.NONC;
        break;
      case '4':
        selection = ApoEStatus.NYD;
        break;
      default:
        selection = ApoEStatus.U;
        break;
    }
    setRegistrationForm({
      ...registrationForm,
      apoEStatus: selection,
    });
    setApoEStatusOptionIndex(+event.target.value);
  };

  const handleAmyloidStatusDropDownChange = (event: SelectChangeEvent) => {
    let selection: AmyloidStatus;
    switch (event.target.value) {
      case '0':
        selection = AmyloidStatus.U;
        break;
      case '1':
        selection = AmyloidStatus.ABP;
        break;
      case '2':
        selection = AmyloidStatus.ABN;
        break;
      case '3':
        selection = AmyloidStatus.NYD;
        break;
      default:
        selection = AmyloidStatus.U;
        break;
    }
    setRegistrationForm({
      ...registrationForm,
      amyloidStatus: selection,
    });
    setAmyloidStatusOptionIndex(+event.target.value);
  };

  const isSubmitDisabled =
    !registrationForm.contentPackID ||
    registrationForm.participantID !== registrationForm.confirmParticipantID ||
    registrationForm.participantID === '' ||
    registrationForm.age === '' ||
    registrationForm.gender === Gender.U ||
    registrationForm.diagnosis === Diagnosis.U ||
    registrationForm.apoEStatus === ApoEStatus.U ||
    registrationForm.amyloidStatus === AmyloidStatus.U ||
    registrationForm.educationYears === '';

  return (
    <div>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div>
          <FormattedMessage id="app.form.description"></FormattedMessage>
        </div>
        <label htmlFor="participantID" style={{ width: '100%', fontWeight: '500' }}>
          {intl.formatMessage({ id: 'app.form.provideParticipantId' })}
        </label>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <Typography>{siteId} </Typography>
          </Grid>
          <Grid item>
            <Typography>{'-'}</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              id="participantID"
              name="participantID"
              value={registrationForm.participantID}
              autoComplete="off"
              error={err}
              helperText={err ? intl.formatMessage({ id: 'app.form.genError' }) : undefined}
              onChange={handleInputChange}
              onClick={handleInputClick}
              InputLabelProps={{ shrink: true }}
              placeholder={intl.formatMessage({ id: 'app.form.provideParticipantIdPlaceholder' })}
              fullWidth
            />
            {registrationForm.participantID.length > 0 && (
              <span className={styles.iconContainer} onClick={() => handleClearInput('participantID')}>
                <CancelOutlinedIcon className={styles.icon} />
              </span>
            )}
          </Grid>
        </Grid>
        <label htmlFor="confirmParticipantID" style={{ width: '100%', fontWeight: '500' }}>
          {intl.formatMessage({ id: 'app.form.confirmParticipanID' })}
        </label>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item>
            <Typography>{siteId} </Typography>
          </Grid>
          <Grid item>
            <Typography>{'-'}</Typography>
          </Grid>
          <Grid item xs={7}>
            <TextField
              id="confirmParticipantID"
              name="confirmParticipantID"
              value={registrationForm.confirmParticipantID}
              autoComplete="off"
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
              onChange={handleInputChange}
              InputLabelProps={{ shrink: true }}
              placeholder={intl.formatMessage({ id: 'app.form.provideParticipantIdPlaceholder' })}
              fullWidth
            />
            {registrationForm.confirmParticipantID.length > 0 && (
              <span className={styles.iconContainer} onClick={() => handleClearInput('confirmID')}>
                <CancelOutlinedIcon />
              </span>
            )}
          </Grid>
        </Grid>
        {contentPackIds && contentPackIds.length > 1 && (
          <div className={styles.radioContainer}>
            <label
              id="content-pack-group-label"
              htmlFor="content-pack-group-label"
              style={{ width: '100%', fontWeight: '500' }}
            >
              {intl.formatMessage({ id: 'app.form.selectContentPack' })}
            </label>
            <FormControl component="fieldset">
              <RadioGroup
                aria-labelledby="content-pack-group-label"
                name="content-pack-group"
                value={registrationForm.contentPackID}
                onChange={handleRadioChange}
                sx={{ gap: 4 }}
              >
                {contentPackIds.map((contentPackId, index) => (
                  <FormControlLabel
                    key={`control-${index}`}
                    control={
                      <Radio
                        name={'content-pack-' + languagePacks[contentPackId].value}
                        id={languagePacks[contentPackId].value}
                        value={languagePacks[contentPackId].value}
                      />
                    }
                    label={languagePacks[contentPackId].name}
                    htmlFor={languagePacks[contentPackId].value}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </div>
        )}
        <label htmlFor="age" style={{ width: '100%', fontWeight: '500' }}>
          {intl.formatMessage({ id: 'app.form.participantAge' })}
        </label>
        <div className={styles.inputContainer}>
          <TextField
            id="age"
            name="age"
            value={registrationForm.age}
            autoComplete="off"
            error={ageError}
            helperText={ageError ? intl.formatMessage({ id: 'app.form.ageError' }) : undefined}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            placeholder={intl.formatMessage({ id: 'app.form.participantAgePlaceholder' })}
            fullWidth
          />
          {registrationForm.age.length > 0 && (
            <span className={styles.iconContainer} onClick={() => handleClearInput('age')}>
              <CancelOutlinedIcon />
            </span>
          )}
        </div>
        <label htmlFor="gender" style={{ width: '100%', fontWeight: '500' }}>
          {intl.formatMessage({ id: 'app.form.participantGender' })}
        </label>
        <div className={styles.inputContainer}>
          {/* https://stackoverflow.com/questions/55184037/react-testing-library-on-change-for-material-ui-select-component */}
          <Select
            id="gender"
            inputProps={{
              id: 'gender',
              'aria-label': 'gender',
            }}
            value={String(genderOptionIndex)}
            onChange={handleDropDownChange}
            sx={{
              width: '100%',
              maxWidth: '630px',
              height: '70px',
            }}
            native={true}
          >
            {genderOptions.map((option, i) => (
              <option value={i} key={`${option}-${i}`}>
                {option}
              </option>
            ))}
          </Select>
        </div>

        <label htmlFor="diagnosis" style={{ width: '100%', fontWeight: '500' }}>
          {intl.formatMessage({ id: 'app.form.participantDiagnosis' })}
        </label>
        <div className={styles.inputContainer}>
          <Select
            id="diagnosis"
            inputProps={{
              id: 'diagnosis',
              'aria-label': 'diagnosis',
            }}
            value={String(diagnosisOptionIndex)}
            onChange={handleDiagnosisDropDownChange}
            sx={{
              width: '100%',
              maxWidth: '630px',
              height: '70px',
            }}
            native={true}
          >
            {diagnosisOptions.map((option, i) => {
              return (
                <option value={i} key={`${option}-${i}`}>
                  {option}
                </option>
              );
            })}
          </Select>
        </div>

        <label htmlFor="apo-e-status" style={{ width: '100%', fontWeight: '500' }}>
          {intl.formatMessage({ id: 'app.form.participantApoEStatus' })}
        </label>
        <div className={styles.inputContainer}>
          <Select
            id="apo-e-status"
            inputProps={{
              id: 'apo-e-status',
              'aria-label': 'apo-e-status',
            }}
            value={String(apoEStatusOptionIndex)}
            onChange={handleApoEStatusDropDownChange}
            sx={{
              width: '100%',
              maxWidth: '630px',
              height: '70px',
            }}
            native={true}
          >
            {apoEStatusOptions.map((option, i) => {
              return (
                <option value={i} key={`${option}-${i}`}>
                  {option}
                </option>
              );
            })}
          </Select>
        </div>
        <label htmlFor="amyloid-status" style={{ width: '100%', fontWeight: '500' }}>
          {intl.formatMessage({ id: 'app.form.participantAmyloidStatus' })}
        </label>
        <div className={styles.inputContainer}>
          <Select
            id="amyloid-status"
            inputProps={{
              id: 'amyloid-status',
              'aria-label': 'amyloid-status',
            }}
            value={String(amyloidStatusOptionIndex)}
            onChange={handleAmyloidStatusDropDownChange}
            sx={{
              width: '100%',
              maxWidth: '630px',
              height: '70px',
            }}
            native={true}
          >
            {amyloidStatusOptions.map((option, i) => {
              return (
                <option value={i} key={`${option}-${i}`}>
                  {option}
                </option>
              );
            })}
          </Select>
        </div>

        <label htmlFor="educationYears" style={{ width: '100%', fontWeight: '500' }}>
          {intl.formatMessage({ id: 'app.form.participantYearsOfEducation' })}
        </label>
        <div className={styles.inputContainer}>
          <TextField
            id="years-of-education"
            name="educationYears"
            value={registrationForm.educationYears}
            autoComplete="off"
            error={educationYearsError}
            helperText={educationYearsError ? intl.formatMessage({ id: 'app.form.educationYearsError' }) : undefined}
            onPaste={(e) => {
              e.preventDefault();
              return false;
            }}
            onChange={handleInputChange}
            InputLabelProps={{ shrink: true }}
            placeholder={intl.formatMessage({ id: 'app.form.participantYearsOfEducationPlaceholder' })}
            fullWidth
          />
          {registrationForm.educationYears.length > 0 && (
            <span className={styles.iconContainer} onClick={() => handleClearInput('educationYears')}>
              <CancelOutlinedIcon />
            </span>
          )}
        </div>

        <Button id="generateCode" type="submit" variant="contained" fullWidth={true} disabled={isSubmitDisabled}>
          <FormattedMessage id="app.form.submitButton"></FormattedMessage>
        </Button>

        <TextField
          id="dummy"
          name="dummy"
          InputProps={{
            style: {
              opacity: 0,
            },
          }}
        />
      </form>
    </div>
  );
};

export default Form;
