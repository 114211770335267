import { FormattedMessage } from 'react-intl';
import { Button } from '@mui/material';
import styles from './Result.module.scss';

type Props = {
  participantID: string;
  response: string;
  onRestart: () => void;
};

const Result: React.FC<Props> = ({ participantID, response, onRestart }) => {
  return (
    <div className={styles.resultContainer}>
      <div className={styles.textContainer}>
        <div data-testid="successMessage">
          <FormattedMessage
            id="app.result.successMessage"
            values={{ participantID: <strong>{participantID}</strong> }}
          ></FormattedMessage>
        </div>
        <div style={{ height: '50px' }}></div>
        <div style={{ width: '100%' }}>
          <FormattedMessage id="app.result.codeLabel"></FormattedMessage>
        </div>
        <div data-testid="code" className={styles.registrationCode}>
          <strong>{response}</strong>
        </div>
        <div>
          <FormattedMessage id="app.result.disclaimer"></FormattedMessage>
        </div>
      </div>
      <Button onClick={onRestart} variant="contained">
        <FormattedMessage id="app.result.back"></FormattedMessage>
      </Button>
    </div>
  );
};

export default Result;
