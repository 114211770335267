import { useMemo, useState } from 'react';
import Form from '../../components/Form/Form';
import Layout from '../../components/Layout/Layout';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import Result from '../../components/Result/Result';
import axios from 'axios';
import { toRegisterParticipantRequest } from '../../models/participantRegistration';
import { FormData } from '../../models/formData';
import { useMsal } from '@azure/msal-react';

export const ParticipantRegistrationPage: React.FC = () => {
  const { instance } = useMsal();
  const user = instance.getActiveAccount();

  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState('');
  const [error, setError] = useState(false);
  const [participantID, setParticipantID] = useState('');

  const handleSubmit = async (formData: FormData) => {
    setError(false);
    setLoading(true); // Start loading screen as soon as submit button clicked
    setParticipantID(formData.participantID);

    const path = '/participant-registrations';
    const registerParticipant = toRegisterParticipantRequest(formData);
    axios
      .post(path, registerParticipant)
      .then(function (resp) {
        setResponse(resp.data.registrationCode);
        setStep(2);
        setLoading(false);
      })
      .catch(function (err) {
        setLoading(false);
        setError(true);
        console.log(err.response);
      });
  };

  const handleRestart = () => {
    setResponse('');
    setStep(1);
  };

  const contentPackIds = useMemo(() => {
    const idTokenClaims = user?.idTokenClaims;
    if (idTokenClaims && idTokenClaims.extension_ContentPackIDs) {
      const contentPackIDsStr = idTokenClaims.extension_ContentPackIDs as string;
      return contentPackIDsStr.split(',');
    } else {
      return [];
    }
  }, [user]);

  return (
    <Layout loading={loading}>
      {loading && <LoadingScreen messageId="app.loading.codeMessage" />}
      {step === 1 && !loading && (
        <Form
          contentPackIds={contentPackIds}
          error={error}
          onSubmit={handleSubmit}
          siteId={String(user?.idTokenClaims?.extension_SiteID)}
        ></Form>
      )}
      {step === 2 && <Result participantID={participantID} response={response} onRestart={handleRestart}></Result>}
    </Layout>
  );
};
