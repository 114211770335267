import { AccountInfo } from '@azure/msal-browser';
import { useEffect, useState } from 'react';
import axios from 'axios';
import './Dashboard.scss';
import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import { useMsal } from '@azure/msal-react';

export const DashboardPage: React.FC<DashboardPageProps> = ({ isAuthReady }: DashboardPageProps) => {
  const { instance } = useMsal();
  const user = instance.getActiveAccount();

  const [loading, setLoading] = useState(true);
  const [embedUrl, setEmbedUrl] = useState('');
  const [reportId, setReportId] = useState('');
  const [accessToken, setAccessToken] = useState('');
  const [error, setError] = useState(false);

  console.log(loading);

  useEffect(() => {
    if (!isAuthReady) return;
    console.log(isAuthReady);
    const path = '/dashboard-embed-url';

    axios
      .get(path)
      .then(function (resp) {
        setEmbedUrl(resp.data.embedUrl[0].embedUrl);
        setReportId(resp.data.embedUrl[0].reportId);
        setAccessToken(resp.data.accessToken);
        setLoading(false);
      })
      .catch(function (err) {
        setLoading(false);
        setError(true);
        console.log(err);
      });
  }, [isAuthReady]);

  return !loading ? (
    !error ? (
      <PowerBIEmbed
        embedConfig={{
          type: 'report', // Supported types: report, dashboard, tile, visual, qna and paginated report
          id: reportId,
          embedUrl: embedUrl,
          accessToken: accessToken, // Keep as empty string, null or undefined
          tokenType: models.TokenType.Embed,
        }}
      />
    ) : (
      <div>Something went wrong</div>
    )
  ) : (
    <LoadingScreen messageId="app.loading.dashboardMessage"></LoadingScreen>
  );
};

export interface DashboardPageProps {
  isAuthReady: boolean;
}
