// Language ISO 639-1 followed by Country ISO 3166

interface LanguagePacks {
  [key: string]: { name: string; value: string };
}

export const languagePacks: LanguagePacks = {
  'ES-ES': { name: 'Spanish', value: 'ES-ES' },
  'CA-ES': { name: 'Catalan', value: 'CA-ES' },
  'EN-US': { name: 'English', value: 'EN-US' },
};
