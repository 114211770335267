import { useEffect, useMemo, useState } from 'react';
import { IntlProvider } from 'react-intl';
import messages from './lang/en.json';
import { createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import { alpha } from '@mui/material';
import { AuthenticatedTemplate, MsalProvider } from '@azure/msal-react';
import { AccountInfo, IPublicClientApplication } from '@azure/msal-browser';
import { getConfig, loginRequest } from './Config';
import axios from 'axios';
import { ParticipantRegistrationPage } from './pages/ParticipantRegistration/ParticipantRegistration';
import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import { DashboardPage } from './pages/Dashboard/Dashboard';
import { Nav } from './components/Nav/Nav';

const config = getConfig();
axios.defaults.baseURL = config.apiEndpoint;

const theme = createTheme({
  palette: {
    primary: {
      main: '#205493',
    },
    secondary: {
      main: alpha('#3F72B0', 0.2),
    },
  },
  typography: {
    body1: {
      fontSize: 20,
    },
    button: {
      textTransform: 'none',
      fontSize: 24,
      letterSpacing: 0,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '10px',
          padding: '20px',
          border: '2px',
          borderStyle: 'solid',
          borderColor: 'white',
          maxHeight: '50px',
          maxWidth: '300px',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '65px',
          maxWidth: '630px',
        },
      },
    },
  },
});

interface AppProps {
  instance: IPublicClientApplication;
}

const App = ({ instance }: AppProps) => {
  const [isAuthReady, setIsAuthReady] = useState(false);

  async function silentRefresh(activeAccount: AccountInfo) {
    const tokenResponse = await instance.acquireTokenSilent({
      account: activeAccount,
      scopes: ['openid', 'profile', 'offline_access', getConfig().msalConfig.auth.clientId],
    });
    if (!tokenResponse) {
      setIsAuthReady(false);
      instance.logoutRedirect({ account: activeAccount });
    }
    axios.defaults.headers.common.Authorization = `bearer ${tokenResponse.idToken}`; //id token is sufficient for verification here
    setIsAuthReady(true);
  }

  async function handleLogin() {
    try {
      await instance.handleRedirectPromise();
      const activeAccount = instance.getActiveAccount();
      if (activeAccount) {
        try {
          await silentRefresh(activeAccount);
        } catch (acquireTokenSilentError) {
          console.log(acquireTokenSilentError);
          instance.logoutRedirect({ account: activeAccount });
        }
      } else {
        await instance.loginRedirect(loginRequest);
      }
    } catch (loginError) {
      console.log(loginError);
    }
  }

  useEffect(() => {
    handleLogin();
    axios.interceptors.request.use(
      async (requestConfig) => {
        await handleLogin();
        return requestConfig;
      },
      (error) => Promise.reject(error)
    );
  }, []);

  const router = createBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Nav />}>
        <Route path="/dashboard" element={<DashboardPage isAuthReady={isAuthReady} />} />
        <Route index element={<ParticipantRegistrationPage />} />
      </Route>
    )
  );

  return (
    <MsalProvider instance={instance}>
      <ThemeProvider theme={theme}>
        <IntlProvider locale="en" messages={messages}>
          <AuthenticatedTemplate>
            <RouterProvider router={router} />
          </AuthenticatedTemplate>
        </IntlProvider>
      </ThemeProvider>
    </MsalProvider>
  );
};

export default App;
